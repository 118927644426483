
.home{
    
    .container{ 
        display: flex;
        margin: 0 auto;
          .center{
            border: 1px solid#D8D8D8;
            width: 60%;
            flex-direction: column;
            display: flex;
    
            .title__wrapper{
                display: flex;
                height: 60px;
                padding: 0 20px;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid#D8D8D8;
                button{
                    border: none;
                    background-color: transparent;
                }
            }
            .contents{
                .mail{
                    border-bottom: 1px solid#D8D8D8;
                    padding: 15px 20px;
                    .avatar{
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        
                    }
                    .avatar__wrapper{
                        
                        display: flex;
                        align-items: center;
                        input{
                            border: none;
                            background-color: transparent;
                            padding: 0 15px;
                            outline: none;
                            width: 90%;
                            
                        }
                    }
                    .icons__wrapper{
                        display: flex;
                        align-items: center;
                        margin-left: 80px;
                        justify-content: space-between;
                        
                        span{
                            .foto{
                                visibility: hidden;
                                position: absolute;
                            }
                            img{
                                margin-right: 23px;
                            }
                        }
                        button{
                            background: #1DA1F2;
                            border-radius: 50px;
                            padding: 15px 30px;
                    
                            color: #fff;
                            font-weight: bold;
                            border: none;
                            
                            &:active{
                                background-color: chocolate;
                            }
                        }
                        
                    }
                }
                .two{
                    
                    position: relative;
                    height: 110px;
                    .user__name__wrapper{
                        display: flex;
                        align-items: center;
                        .text__wrapper{
                            padding-left: 15px;
                            p{
                                padding-top: 6px;
                            }
                            .name{
                                display: inline-block;
                                padding-right: 4px;
                            }
                            .two__text{
                                width: 550px;
                            }
                            .thre__text{
                                width: 150px;
                            }
                            .btns{
                                background-color: transparent;
                                border: none;
                                font-weight: bold;
                                font-size: 20px;
                                position: absolute;
                                right: 25px;
                                top: 15px;
                            }
                        }
                    }
                    .icon__wrapper{
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        width: 80%;
                        position: absolute;
                        bottom: 15px;
.labes{
    position: relative;

    .chexbox{
        visibility: hidden;
    }
.like{
    z-index: 20;    
        position: absolute;
}

    .chexbox:checked ~ .likes{
       visibility: hidden;
    }
    .chexbox:checked ~ .like{
        visibility:visible;
    }

}

                        p{
                            display: flex;
                            align-items: center;
                            img{
                                padding-right: 10px;
                            }
                        }
                    }
                }
                .thre{
                    
                    height: 475px;
                    
  
                    .btns{
                        margin-left: -100px;
                    }
                }
                
                .shashlik{
                    width: 75%;
        top:90px;
        left: 95px;
        position: absolute;   
                    
                }
                
            }
        }
         
    }
}
