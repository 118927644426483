*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
ul{
    list-style: none;
}

.navigatsiya{

    width: 300px;
    
    .logo{
        margin: 20px 0 20px 55px;
    }
    
    .menu{
        margin: 0 auto;
        width: 80%;
        .menu__list{
            padding: 15px 20px;
            border-radius: 50px;
            font-weight: bold;
            &:hover{
                background-color: #ccc;
            &:last-child{
                background-color: transparent;
            }
            }
            
     
            .menu__link{
                display: flex;
                align-items: flex-end;
                text-decoration: none;
                color: black;
                font-family: 'Segoe UI', sans-serif;
                font-size: 18px;
                font-weight: 600;
                
        

                img{
                    margin-right: 10px;
                }
            }
        }
    }
    .tweetbtn{
        font-family: 'Segoe UI', sans-serif;
font-size: 18px;
font-weight: 700;
color: #ffffff;
margin-left: 33px;
padding:18px 100px;
border-radius: 27px;
background: #1D9BF0;
border: none;
    }

    .logoutbtn{
        background-color: transparent;
        padding: 10px;
        position: absolute;
        bottom: -50px;
        left: 30px;
        border-radius: 30px;
    }
    
}