     
     .fallowers{
            width: 30%;


            .search__wrapper{
            display: flex;

            .search__icon{
                position: relative;
                 left: 50px;
            opacity: 0.3;
            top: 0;
                }

input{
    height: 50px;
    width: 87%;
    margin: 5px 0 10px 0;
    outline: none;
border: none;
padding:0 10px 0 60px;
border-radius: 31px;
background: #EFF3F4;

}
            }
            .trends{
            background: #F7F9F9;
width: 80%;
margin: 0 auto;
padding:20px 15px ;
border-radius: 10px;
margin-bottom: 30px;

.more__btn{

    text-decoration: none;
}

.col{

margin-bottom: 15px;
    .subtitle{
        font-family: 'Segoe UI', sans-serif;
font-size: 20px;
font-weight: 600;
color: #000000;

    }

    .subtext{


        color: #393B41;

    }
}

.settings__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
padding-bottom: 30px;
    .settings__title{
        font-family: 'Segoe UI', sans-serif;
font-size: 24px;
font-weight: 700;
color: #000000;
    }
    

    .settings__btn{
        background-color: transparent;
        border: none;
    }

}



            }
            .likes{
                height: 265px;
width: 80%;
margin: 0 auto;
padding: 15px;
border-radius: 10px;
background: #F7F9F9;
display: flex;
flex-direction: column;
height: 600px;
.likes__blok{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    position: relative;
.link{
    position: absolute;
    left: 80px;
    text-decoration: none;
    color: #000000;
    .like__username{
      color: #0000008e;

    }
}

.fallow__btn{
color: #fff;
border-radius: 50px;
padding: 10px 13px;
background: #000000;
border: none;
    }
}

.avatar{
height: 60px;
width: 60px;
border-radius: 50%;
object-fit: cover;
}

.more__btn{
    padding-top: 40px;
    text-decoration: none;
}

            }



            .links{
                width: 300px;
          padding:30px 20px;
  
                display: flex;
                flex-wrap: wrap;
                .link{
                    font-family: 'Segoe UI', sans-serif;
font-weight: 400;

                    display: inline;
                padding-right: 10px;

                }
            }
        }
        
      