.profile{
    .container{
        display: flex;
justify-content: space-between;
     .center{
            border: 1px solid#D8D8D8;
            width: 60%;
            flex-direction: column;
            display: flex;
    
            .title__wrapper{
                display: flex;
                height: 60px;
                padding: 0 20px;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid#D8D8D8;
.name__wrapper{
    display: flex;
    align-items: center;

    .backarrow{
        font-size: 25px;
        padding-right: 20px;
    }

}

                button{
                    border: none;
                    background-color: transparent;
                }
            }
            .contents{
                .mail{
                    position: relative;
                    border-bottom: 1px solid#D8D8D8;
                .images{
                    height: 250px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url(../../img/bigbg.jpg);
                    position: relative;
                        .avatar{
                            position: absolute;
                       height: 100px;
width: 100px;
bottom: -50px;
left: 20px;
                        border-radius: 50%;
                        border: 4px solid #FFFFFF;
                        object-fit: cover;
                        
                    }
                }
                .edit{
             top: 260px;
             border: 2px solid;
                    padding:8px 10px;
                    position: absolute;
                    right: 20px;
font-size: 16px;
font-weight: 600;
border-radius: 30px;
background-color: transparent;
                }
                 
               .text__wrapp{
              
                    padding: 60px 20px 0 20px;
.name{
font-size: 24px;
font-weight: 700;

}
.username{
    color: #0000008e;
    display: block;
}
.names{
    padding: 10px 0;
font-size: 18px;
display: inline-block;

}
.oficusername{
    color: #1DA1F2;

}
.about__links{
    display: flex;
    justify-content: space-between;
    .gio{
       color: #0000008e;

    }
    .tme{
        color: #1DA1F2;
        text-decoration: none;
    }
}
.follows{
    display: flex;
    span{
        display: flex;
        align-items: flex-end;
        padding:10px 30px 0 0;

        .follow{
            padding-left: 5px;
           color: #0000008e;

        }
    }
}

.pages_link{
    padding: 30px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    .link{
font-size: 18px;
text-decoration: none;
color: #000000;
font-weight: 400;
padding-bottom: 10px;

    }
    .active-link{
font-weight: 700;
        border-bottom: 3px solid #1DA1F2;
    }
}
               }
                }
                .two{
                    padding: 15px 20px;
                    
                    position: relative;
                 .avatar{
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        
                    }
                    .user__name__wrapper{
                        display: flex;
                        align-items: center;
                        .text__wrapper{
                            padding-left: 15px;
                            p{
                                padding-top: 6px;
                            }
                            .name{
                                display: inline-block;
                                padding-right: 4px;
                            }
                            .two__text,  .one__text{
                                padding-bottom: 5px;
                                width: 550px;
                            }
                         
                            .btns{
                              
                                background-color: transparent;
                                border: none;
                                font-weight: bold;
                                font-size: 20px;
                                position: absolute;
                                right: 25px;
                                top: 15px;
                            }
                        }
            
                    }
                    .three{
                        position: absolute;
                        bottom: 15px;
                    }
                    .icon__wrapper{
                        display: flex;
                        justify-content: space-between;
                        width: 80%;
                        padding-left: 75px;

.labes{
    position: relative;

    .chexbox{
        visibility: hidden;
    }
.like{
    z-index: 20;    
        position: absolute;
}

    .chexbox:checked ~ .likes{
       visibility: hidden;
    }
    .chexbox:checked ~ .like{
        visibility:visible;
    }

}

                        p{
                            display: flex;
                            align-items: center;
                            img{
                                padding-right: 10px;
                            }
                        }
                    }
                }
                .thre{
                    
                    height: 475px;
                    
  
                    .btns{
                        margin-left: -100px;
                    }
                }
                
                .shashlik{
                    width: 75%;
        top:90px;
        left: 95px;
        position: absolute;   
                    
                }
                
            }
        }
 
    }
}
