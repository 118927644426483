.wrapper{
    display: flex;
    align-items: center;
    background-color: #F3F3F3;
position: relative;
    width: 100vw;
    height: 100vh;
    justify-content: center;
}

.form{
position: relative;
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 450px;
    padding:  20px;
    height: 420px;

flex-direction: column;
margin: 0 auto;
}

.login__title{
    font-family: 'Roboto', sans-serif;
font-size: 40px;
font-weight: 900;
line-height: 49px;
margin: 20px 0;
}
.login__input{
    border: 1px solid #00000033;
    outline: none;
width: 400px;
padding: 20px;
border-radius: 6px;
margin-bottom: 25px;
}

input::placeholder{
    color: #00000099;
font-size: 18px;
}
.loginbtn{

background: #1DA1F2;
height: 60px;
width: 440px;
border-radius: 76px;
font-weight: bold;
font-size: 18px;
color: #fff;
border: none;
}

.sigin{
    text-align: left;
    text-decoration: none;
    font-family: 'Segoe UI', sans-serif;
font-size: 18px;
font-weight: 400;
position: absolute;
left: 20px;
bottom: 15px;


}

.sigin:nth-child(2n){

    left: 320px;
}
